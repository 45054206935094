import React from 'react';
import Randomizer from './Randomizer';


function App() {
  return (
    <div className="App">
      <Randomizer />
    </div>
  );
}

export default App;
